import React from "react";
import "./Container.css";

const Container = props => {
  const { className, children } = props;

  return (
    <>
      <div className={"Container " + className}>{children}</div>
    </>
  );
};

Container.defaultProps = {
  className: "",
  children: null
};

export default Container;