import React, { Component } from "react"
import { Link } from "gatsby"

import "./header.css"

import logo from "../images/logo@2x.png"

class header extends Component {
  constructor(props) {
    super(props)
    this.navData = [
      { index: 0, url: "/", text: "Hem" },
      { index: 1, url: "/about/", text: "Ul Juridik" },
      { index: 2, url: "/missions/", text: "Uppdrag" },
      { index: 3, url: "/contact/", text: "Kontakt" },
    ]
    this.state = {
      data: this.getNavData(),
    }
    this.displayLogo = this.displayLogo.bind(this)
  }
  getNavData() {
    const { location } = this.props
    let url = location.pathname
    let items = []
    this.navData.map(item => {
      items.push({
        active: url === item.url,
        url: item.url,
        text: item.text,
      })
      return null
    })
    return items
  }
  displayLogo() {
    return (
      <Link to="/">
        <img src={logo} className="Header-logo" alt="Logo" />
      </Link>
    )
  }
  render() {
    const { data } = this.state

    return (
      <div className="Header">
        {this.displayLogo()}
        <ul className="Header-navigation">
          {data.map((item, i) => {
            const className = item.active ? "Header-active-item" : ""
            return (
              <li key={i} className={"Header-navigation-item " + className}>
                <Link to={`${item.url}`}>{item.text}</Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

export default header
