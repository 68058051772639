/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Location } from "@reach/router"

import Container from "./Container"
import Header from "./header"

import "./layout.css"

const Layout = props => {
  const { children } = props;

  return (
    <Container>
      <Location>
        {locationProps => <Header {...locationProps} {...props} />}
      </Location>
      {children}
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
